import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  from
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      'x-hasura-admin-secret': process.env.REACT_APP_HASURA_SECRET,
      'content-type': 'application/json'
    }
  })

  // Call the next link in the middleware chain.
  return forward(operation)
})

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
      graphQLErrors?.map(({ message, extensions }) => {
        switch (extensions.code) {
          case 'data-exception':
          case 'validation-failed':
            // <Link to="/something-went-wrong"></Link>
            break
          default:
            // default case

            console.log(extensions.code)
        }

        return 0;
      })
    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
      // this.props.history.push("/network-error");
    }
  }
)

const link = from([
  errorLink,
  new HttpLink({ uri: process.env.REACT_APP_GRAPHQL })
])

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all'
    }
  }
})
