import React from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import dolphin from '../assets/Mpm_Coming-Soon-Success-Illustration_22-09-23 1.svg'

const Modal = ({ open, setOpen }) => {
  return (
    <div>
      <Transition appear show={open} as={Fragment}>
        <Dialog as='div' className='relative z-30' onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                 <div className='fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity'></div>
              </Transition.Child>
              <Dialog.Panel className='w-full max-w-2xl mt-3 transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg flex flex-row justify-end items-end px-6 pt-6'
                  >
                    <div className=' bg-[#F0F1F4] rounded-full p-2'>
                      <XIcon onClick={() => setOpen(false)} className='w-5 text-[#474A50]' />
                    </div>
                  </Dialog.Title>
                  <div className='bg-white px-6 py-12 pt-5 pb-4 lg:mt-0 rounded-2xl flex flex-col justify-center items-center text-center'>
                    <img className=' mb-5 w-[160px]' src={dolphin} alt='/' />
                    <h2 className=' text-4xl font-semibold leading-10 tracking-[-0.78px] text-[#0E2446] mb-2'>
                      Thanks for signing up!
                    </h2>
                    <p className=' md:text-lg text-sm md:font-normal md:leading-[24px] leading-[150%] md:tracking-[0.2px] tracking-[0.13px] text-[#0E2446] mb-6'>
                      Check your inbox for our welcome email.
                    </p>
                  </div>
                </Dialog.Panel>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  )
}

export default Modal
