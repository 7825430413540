import './App.css'
import logo from './assets/mpm-log.svg'
import logoText from './assets/mpm-log-text.svg'
import logoTextWhite from './assets/mpm-log-text-white.svg'
// import Timer from './components/timer'
import hero from './assets/Hero-Image.png'
import { useMutation, gql } from '@apollo/client'
import { useState } from 'react'
import {
  ErrorNotification
} from './components/Notification'
import Modal from './components/modal'

const ADD_TO_WAITLIST = gql`
  mutation earlyBirdEntry($email: String!, $name: String!) {
    insert_earlybird_emails_one(object: { email: $email, name: $name }) {
      id
      created_at
    }
  }
`

function App () {
  const [email, setEmail] = useState('')
  const [fullName, setFullName] = useState('')
  const [open, setOpen] = useState(false);

  const [addEarlyBirdEmail, { loading }] = useMutation(ADD_TO_WAITLIST, {
    variables: {
      email: email,
      name: fullName
    },
    onCompleted: () => {
     
      setOpen(true)

      setEmail('')
      setFullName('')
    },
    onError: error => {
      ErrorNotification(
        'Sorry we were unable to add you to the waiting list, please try again later'
      )
      console.log(error)
    }
  })

  return (
    <>
      <div className='GeeksForGeeks h-screen flex flex-col lg:justify-start justify-center bg-no-repeat lg:bg-center bg-[center_top_5rem] xl:pt-10 pt-20'>
        <div className='flex lg:flex-row flex-col justify-center lg:mt-[1%] xl:mt-14 gap-10'>
          <div className='flex flex-col lg:pt-24 lg:gap-8 gap-2 z-20'>
            <div className='flex lg:justify-start justify-center items-center gap-4'>
              <img className='h-6 lg:h-8' src={logo} alt='' />
              <img className='h-4 lg:h-10 hidden lg:inline-block' src={logoText} alt='' />
              <img className='h-6 lg:h-10 lg:hidden inline-block' src={logoTextWhite} alt='' />
            </div>
            <div className='flex lg:text-start text-center lg:justify-start justify-center lg:mb-0 mb-4 mx-2 md:mx-auto'>
              <p className='font-semibold lg:w-[610px] md:w-[475px] text-white lg:text-[#0E2446] lg:text-6xl md:text-4xl text-[39px] leading-[120%] tracking-[-1.22px]'>
                Buying Insurance Just Got Easier
              </p>
            </div>
            <div className='flex lg:justify-start justify-center lg:mb-0 mb-4'>
              <button className='border-2 border-orange-500 rounded-lg px-3 py-2 text-white bg-orange-500'>
                Coming Soon
              </button>
            </div>
            {/* <div className='flex md:-mt-12 -mt-4 md:mb-0 mb-10'>
              <Timer />
            </div> */}
            <div className='flex lg:justify-start justify-center'>
              <p className='md:text-xl text-[10px] leading-[120%] w-[60%] lg:w-5/6 font-normal md:tracking-[0.2px] tracking-[0.1px] text-white lg:text-[#0E2446] md:mt-0 md:text-start text-center'>
                Get early access and stand a chance to win cool prizes!
              </p>
            </div>
            <div>
              <form
                onSubmit={e => {
                  e.preventDefault()
                  addEarlyBirdEmail()
                }}
                className='flex flex-col lg:items-start items-center  gap-4 px-10 md:px-0'
              >
                <input
                  type='text'
                  className='border-2 border-[#296DD2] h-10 sm:w-[70%] md:w-[60%] lg:w-5/6 w-full rounded-lg pl-4'
                  placeholder='Name'
                  required
                  value={fullName}
                  onChange={e => {
                    e.preventDefault()
                    setFullName(e.target.value)
                  }}
                />
                <input
                  type='email'
                  className='border-2 border-[#296DD2] h-10  sm:w-[70%] md:w-[60%] lg:w-5/6 w-full rounded-lg pl-4'
                  placeholder='Email'
                  required
                  value={email}
                  onChange={e => {
                    e.preventDefault()
                    setEmail(e.target.value)
                  }}
                />
                <button
                  type='submit'
                  disabled={loading}
                  className='bg-[#296DD2] text-[#E6F5FF]  sm:w-[70%] md:w-[60%] lg:w-5/6 w-full md:px-6 lg:py-2 py-3 mb-10 rounded-lg text-sm'
                >
                  Get early access
                </button>
              </form>
            </div>
          </div>
          <div className='flex justify-center  mb-6 absolute top-0 left-0 lg:relative w-[100vw] lg:h-auto lg:w-auto'>
            <div className='lg:hidden absolute top-0 right-0 left-0 bottom-0 bg-[#000000AD] '/>
            <img
              className='lg:h-[80vh] lg:w-auto w-[100vw] h-[100vh] object-cover lg:object-scale-down'
              src={hero}
              alt=''
            />
          </div>
        </div>
      </div>
      <Modal open={open} setOpen={setOpen} />
    </>
  )
}

export default App
